<template>
    <div class="my-portal-table">
        <b-table responsive striped :items="data" :fields="fields" :sort-compare="customDateSortCompare">
            <template v-for="(_, slotName) of $scopedSlots" v-slot:[slotName]="scope">
                <slot v-if="data && data.length > 0" :name="slotName" v-bind="scope"></slot>
            </template>
            <template v-if="data.length === 0" slot="bottom-row">
                <td :colspan="fields.length">
                    <span class="d-flex justify-content-center">Ni podatkov za prikaz</span>
                </td>
            </template>
        </b-table>
    </div>
</template>

<script>
    import {BTable} from 'bootstrap-vue'
    import dayjs from 'dayjs'
    export default {
        components: {BTable},
        props: {
            data: {
                type: Array
            },
            fields: {
                type: Array
            }
        },
        methods:{
            customDateSortCompare(a, b, key) {
                if (key === 'date_odcitki') {
                    // date_odcitki field is a `Date` object, so subtraction works on the date serial number (epoch value)
                    return dayjs(`${a['obracun_mesec']  }.01.${  a['obracun_leto']}`) - dayjs(`${b['obracun_mesec']  }.01.${  b['obracun_leto']}`)

                } else {
                    // Let b-table handle sorting other fields (other than `date` field)
                    return false
                }
            }
        }
    }
</script>

<style>
.my-portal-table .table th {
  background-color: #dbe9f5 !important;
  border-top: none;
  border-bottom: none;
  text-transform: none;
  padding-top: 10px !important;
  padding-bottom: 20px !important;
}
.my-portal-table .table thead tr {
  border-bottom: 2px solid #72a5d8;
  border-right: 30px solid #dbe9f5 !important;
  border-left: 30px solid #dbe9f5 !important;
  color: #72a5d8 !important;
  font-weight: bold;
  background: #dbe9f5 !important;
}
.my-portal-table .table tbody tr {
  background-color: #eaf3f9 !important;
  border-top: 1px solid #72a5d87e;
  border-right: 30px solid #eaf3f9;
  border-left: 30px solid #eaf3f9;
}
.my-portal-table .table tfoot {
  background-color: #eaf3f9 !important;
  border-top: 2px solid #72a5d87e;
  border-right: 30px solid #eaf3f9;
  border-left: 30px solid #eaf3f9;
  border-bottom: 20px solid #eaf3f9;
  font-weight: bold;
}
</style>