<template>
    <b-overlay :show='showLoader'>
        <b-card class="suppliers">
            <h1 class="text-secondary font-title">Dokumenti dobaviteljev</h1>
            <b-row class="mt-1">
                <b-col>
                    <h4 class="mb-0 font">Filtriraj fakture:</h4>
                </b-col>
            </b-row>
            <b-row class="m-0 mt-1" align-v="center" style="font-size: 13px;">
                <b-col cols="12" md="6" xl="4" class="mt-1 mt-xl-0 pl-0">
                    <Select v-model="filter.unit" :options="units" label="naziv" placeholder="Enota" @change="loadData" :customDropdownLabelFn="customLabelFunction" :customSelectedLabelFn="customLabelFunction"/>
                </b-col>
                <b-col cols="12" md="6" xl="2" class="mt-1 mt-xl-0 pl-0">
                    <Select v-model="filter.date" :options="dates" placeholder="Obdobje" @change="loadDocuments" :disabled="!filter.unit"/>
                </b-col>
                <b-col cols="12" md="6" xl="4" class="mt-1 mt-xl-0 pl-0 pl-xl-1">
                    <Select v-model="filter.supplier" :options="suppliers" label="dobavitelj_naziv" placeholder="Dobavitelj" @change="filterData" :disabled="!filter.unit" :clearable="true" />
                </b-col>
                <b-col cols="12" md="6" xl="2" class="mt-1 mt-xl-0 pl-0 pl-md-1">
                    <rounded-button @click="loadDocuments">
                        IŠČI
                        <feather-icon class="ml-1" size="1.2x" icon="SearchIcon" />
                    </rounded-button>
                </b-col>
            </b-row>
            <div v-if="data&& data.length && objekt" class="p-1 mt-2" style="background-color: #72a5d8;">
                <h4 class="m-0 text-white">
                    Objekt: <span class="capitalize">{{objekt.naziv.toLowerCase()}}</span>
                    <span class="ml-1">Enota: <span class="capitalize">{{ filter.unit.sifra.toLowerCase() }}</span></span>
                </h4>
            </div>
            <my-portal-table class="mt-1 mb-2 horizontal-scroll" :data="data" :fields="fields">
                <template #head(obracun)="data">
                    <span class="pr-1">{{ data.label }}</span>
                </template>
                <template #cell(obracun)="row">
                    <span>{{row.item.obracun_leto}}/{{row.item.obracun_mesec}}-{{row.item.obracun_vrsta}}</span>
                </template>
                <template #cell(actions)="data">
                    <a v-if="data.item.has_document" @click="downloadFakturoPDF(data.item.dokument_stevilka, `${data.item.dobavitelj_naziv}_${data.item.dokument_stevilka}`)">
                        <img width="35px" height="35px" :src="require('@/assets/svg/pdf_icon.svg')"/>
                    </a>
                </template>
            </my-portal-table>
            <div class="text-right">
                <rounded-button :disabled="disableButton" @click="downloadZipFakture">
                    PRENESI FAKTURE
                    <fa class="ml-1" icon="download"/>
                    <b-spinner v-if="loadingDocuments" class="ml-1" style="height: 15px; width: 15px;"/>
                </rounded-button>
            </div>
            <!-- <div class="p-1 mt-2" style="background-color: #BFD42E;">
             <h4 class="m-0 text-white font">ZAVAROVANJA</h4>
      </div>

      <b-row class="mt-3">
          <b-col cols="12" md="6">
              <div style="background-color: #FAFBFB;">
                  <div class="mb-0" style="background-color: #B1E1F3;">
                      <h3 class="p-1 font">Izpis zavarovanja</h3>
                  </div>
                  <div class="p-1 mt-0">
                      <b-row>
                          <b-col>
                              <h3>512-0656877.PDF</h3>
                          </b-col>
                          <b-col cols="2" align="right">
                              <a><fa class="text-secondary" icon="file-pdf" size="2x"/></a>
                          </b-col>
                      </b-row>
                  </div>
              </div>
          </b-col>
      </b-row>-->
        </b-card>
    </b-overlay>
</template>

<script>
    import {BRow, BCol, BOverlay, BCard/*, BSpinner*/} from 'bootstrap-vue'
    import Select from '@/views/Components/Select'
    import RoundedButton from '@/views/Components/RoundedButton.vue'
    import MyPortalTable from '@/views/Components/MyPortalTable'
    import {FileMimeType} from '@/libs/mimeTypes'

    export default {
        components: {MyPortalTable, BRow, BCol, BOverlay, BCard,  Select, RoundedButton/*, BSpinner*/},
        data() {
            return {
                dataOrg: [],
                loadingDocuments: false,
                showLoader: false,
                filter: {date: '', unit: '', supplier: ''},
                dates: [],
                units: [],
                suppliers: [],
                fields: [
                    //{ key: 'dokument_stevilka', label: 'Interna št.', sortable: false, class: 'text-left' },
                    //{ key: 'obracun', label: 'Obračun', sortable: false, class: 'text-left' },
                    { key: 'dobavitelj_naziv', label: 'Naziv', sortable: false, class: 'text-left' },
                    { key: 'datum_dokumenta', label: 'Datum dok.', sortable: false, class: 'text-left', formatter: val => { return this.dayjs(val).format('DD.MM.YYYY') } },
                    //{ key: 'datum_valute', label: 'Datum val.', sortable: false, class: 'text-left', formatter: val => { return this.dayjs(val).format('DD.MM.YYYY') } },
                    //{ key: 'dobavitelj_naslov', label: 'Naslov', sortable: false, class: 'text-left' },
                    //{ key: 'dobavitelj_sifra', label: 'Dobaviteljeva št.', sortable: false, class: 'text-left' },
                    { key: 'actions', label: '', sortable: false, class: 'text-left' }
                ],
                data: [],
                objekt: null
            }
        },
        methods: {
            filterData() {
                if (this.filter.supplier) {
                    this.data = this.dataOrg.filter(ele => ele.dobavitelj_sifra === this.filter.supplier.dobavitelj_sifra)

                } else {
                    this.data = this.dataOrg.filter(ele => ele)
                }
            },
            customLabelFunction(unit) {
                return `${unit.naziv} / ${unit.zaporedna}, šifra: ${unit.sifra} (${unit.vrsta === 'L' ? 'Lastnik' : 'Uporabnik'})`
            },
            async loadData() {
                this.showLoader = true
                this.$scrollToTop()
                try {
                    await this.loadDates()
                    await this.loadDocuments()
                    await this.loadObjekt()
                } catch (error) {
                    this.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
                } finally {
                    this.showLoader = false
                }
            },
            async loadObjekt() {
                if (!this.showLoader) this.showLoader = true
                const res = await this.$http.get(`/api/iiportal-user/v1/e_manager/objekt/${this.filter.unit.objekt_sifra}`)
                this.objekt = res.data
            },
            async loadDates() {
                if (!this.showLoader) this.showLoader = true
                this.dates = []
                this.filter.date = ''

                const res = await this.$http.get(`/api/iiportal-user/v1/e_manager/date/dobaviteljevi_racuni/${this.filter.unit.sifra}`)
                this.dates = res.data
                this.filter.date = this.dates[0]
            },
            async loadUnits() {

                if (!this.showLoader) this.showLoader = true

                try {
                    this.units = []
                    this.filter.unit = ''

                    const res = await this.$http.get('/api/iiportal-user/v1/e_manager/enota')
                    for (const unit of res.data) {
                        if (this.units.findIndex(u => u.sifra === unit.sifra) === -1) this.units.push(unit)
                    }

                    this.units.sort((a, b) => {
                        if ((a.naziv === b.naziv && a.zaporedna < b.zaporedna) || (a.naziv < b.naziv)) return -1
                        else if (a.naziv > b.naziv) return 1
                        else return 0
                    })

                    if (this.units.length > 0) {
                        this.filter.unit = this.units[0]
                    }

                } catch (error) {
                    this.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
                } finally {
                    this.showLoader = false
                }
            },
            async loadDocuments() {

                if (!this.showLoader) this.showLoader = true
                try {

                    this.suppliers = []
                    this.filter.supplier = ''
                    this.data = []
                    this.dataOrg = []

                    if (!this.filter.date) return
                    const res = await this.$http.get(`/api/iiportal-user/v1/e_manager/dobaviteljevi_racuni/${this.filter.date}/${this.filter.unit.sifra}/123`)
                    //this.data = res.data
                    this.data = []
                    res.data.forEach((element) => {
                        if (element.has_document === true) {
                            this.data.push(element)
                            this.dataOrg.push(element)
                        }
                    })

                    for (const d of this.data) {
                        if (this.suppliers.findIndex(s => s.dobavitelj_naziv === d.dobavitelj_naziv) === -1) this.suppliers.push(d)
                    }
                    this.suppliers.sort((a, b) => {
                        if (a.dobavitelj_naziv < b.dobavitelj_naziv) return -1
                        else return 1
                    })

                } catch (error) {
                    this.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
                } finally {
                    this.showLoader = false
                }
            },
            downloadFakturoPDF(pdf_id, pdf_title) {
                const thisIns = this
                this.showLoader = true
                const cat = 'dokumenti-dobavitelja'
                this.$http({
                    url: `/api/iiportal-user/v1/attachements/file/${cat}/${this.filter.unit.sifra.toLowerCase()}/${pdf_id}`, //your url
                    method: 'GET',
                    responseType: 'blob' // important
                })
                    .then((response) => {
                        let fileType = 'pdf'
                        fileType = FileMimeType[response.data.type]

                        const url = window.URL.createObjectURL(new Blob([response.data]))
                        const link = document.createElement('a')
                        link.href = url
                        link.setAttribute('download', `${pdf_title}.${fileType}`) //or any other extension
                        document.body.appendChild(link)
                        link.click()
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri nalaganju pdf je prišlo do napake!\n${error.message}`)
                    }).finally(() => {
                        thisIns.showLoader = false
                    })

            },

            downloadZipFakture() {
                const thisIns = this
                this.loadingDocuments = true
                let url = ''
                let fileName = ''
                if (this.filter.supplier !== null && this.filter.supplier !== undefined && this.filter.supplier !== '') {
                    url = `/api/iiportal-user/v1/attachements/zip/${thisIns.filter.date}/${thisIns.filter.supplier.dobavitelj_sifra}/${thisIns.filter.unit.sifra}`
                    fileName = `${thisIns.filter.supplier.dobavitelj_naziv}_${thisIns.filter.date}`
                } else {
                    url = `/api/iiportal-user/v1/attachements/zip/${thisIns.filter.date}/all/${thisIns.filter.unit.sifra}`
                    fileName = `dobaviteljev_${thisIns.filter.date}`
                }
                this.$http({
                    url, //your url
                    method: 'GET',
                    responseType: 'blob' // important
                })
                    .then((response) => {
                        let fileType = 'zip'
                        fileType = FileMimeType[response.data.type]

                        const url = window.URL.createObjectURL(new Blob([response.data]))
                        const link = document.createElement('a')
                        link.href = url
                        link.setAttribute('download', `Fakture_${fileName}.${fileType}`) //or any other extension
                        document.body.appendChild(link)
                        link.click()
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri nalaganju zip je prišlo do napake!\n${error.message}`)
                    }).finally(() => {
                        thisIns.loadingDocuments = false
                    })
            }
        },
        computed: {
            filtered() {
                const filtered = this.data.filter(data => {
                    if (!this.filter.supplier || !this.filter.supplier.dobavitelj_naziv) return true
                    return String(data['dobavitelj_naziv']).toLowerCase().includes(String(this.filter.supplier.dobavitelj_naziv).toLowerCase())
                })
                return filtered.length > 0 ? filtered : []
            },
            disableButton() {
                //disables button if no data is present for the given filter
                if (this.data.length === 0) {
                    return true
                }

                return false
            }
        },
        async mounted() {
            await this.loadUnits()
            await this.loadData()
        }
    }
</script>

<style scoped>
.capitalize {
  text-transform: capitalize;
}
</style>

<style>
.button-no-shadow:hover{
  box-shadow: none !important;
}

.suppliers{
  border-radius: 10px !important;
}
.suppliers .font-title {
  font-weight: bold;
  color: #72a5d8 !important;
}
.suppliers .font {
  font-weight: bold;
}
.suppliers .dropdown .btn, .suppliers .dropdown .dropdown-toggle {
  border-radius: 0;
  border: none;
}
.suppliers .dropdown .btn {
  background-color: #E8E7E6 !important;
  color:black !important;
  box-shadow: none !important;
}

@media (min-width: 571px){
    .horizontal-scroll{
        overflow: clip;
    }
}
@media (max-width: 570px){
    .horizontal-scroll{
        overflow: auto;
    }
}
</style>
